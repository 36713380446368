/**
 * API Client
 */

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import axios, { AxiosError } from 'axios';

import { API_HOST, COOKIE_NAME, DEBUG } from './cfg';

export class ApiError extends Error {
  code?: string;
  message: string;
  error?: {
    name: string;
    status?: number;
    issues?: Array<{
      code: string;
      message: string;
      path: string[];
    }>;
  };
  response?: {
    status: number;
    statusText: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cause?: any;

  constructor(message: string) {
    super(message);
    this.message = message;
    this.name = 'ApiError'; // this will allow instanceOf checks to work
    Object.setPrototypeOf(this, ApiError.prototype); // this is needed for prototype chain to work correctly
  }
}

export type SuccessResponse = {
  ok: boolean;
};

export type JsonValue = Array<JsonValue> | JsonObject | boolean | number | string | null;

type JsonObject = {
  [Key in string]?: JsonValue;
};

export class Client {
  api: AxiosInstance;
  forceOpts: AxiosRequestConfig;

  constructor() {
    const isServer = typeof window === 'undefined';

    const baseURL = isServer ? API_HOST + '/api/v1/' : '/api/v1/';

    this.api = axios.create({
      baseURL,
      responseType: 'json',
    });

    this.forceOpts = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  }

  private async setupHeaders() {
    const isServer = typeof window === 'undefined';
    if (!isServer) return;

    // Hack to get the `Client` to be server/client component without passing cookies explicitly.
    // @see https://github.com/vercel/next.js/issues/49752#issuecomment-1546687003
    // @see https://github.com/vercel/next.js/issues/49757
    const { cookies, headers } = await import('next/headers');

    const addHeaders: RawAxiosRequestHeaders = {};

    const authToken = (await cookies()).get(COOKIE_NAME)?.value;
    if (authToken) {
      addHeaders['Authorization'] = `Bearer ${authToken}`;
    }

    // Passthrough specific HTTP headers
    const headersList = await headers();
    const allowedHeaders: string[] = ['user-agent', 'cf-connecting-ip'];
    for (const h of allowedHeaders) {
      if (headersList.get(h)) {
        addHeaders[h] = headersList.get(h) as string;
      }
    }

    this.setHeaders(addHeaders);
  }

  async get<T = JsonObject>(url: string): Promise<T> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] GET: ${url}`);
    try {
      const req = await this.api.get<T>(url);
      return req.data;
    } catch (e) {
      throw handleError(e as Error);
    }
  }

  async _get<T = JsonObject>(url: string): Promise<AxiosResponse<T, JsonObject>> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] _GET: ${url}`);
    return await this.api.get<T>(url);
  }

  async post<T = JsonObject, D = JsonObject>(url: string, data?: D): Promise<T> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] POST: ${url}`);
    try {
      const req = await this.api.post<T>(url, data, this.forceOpts);
      return req.data;
    } catch (e) {
      throw handleError(e as Error);
    }
  }

  async _post<T = JsonObject, D = JsonObject>(
    url: string,
    data?: D
  ): Promise<AxiosResponse<T, D>> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] _POST: ${url}`);
    return await this.api.post<T>(url, data, this.forceOpts);
  }

  async patch<T = JsonObject, D = JsonObject>(url: string, data?: D): Promise<T> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] PATCH: ${url}`);
    try {
      const req = await this.api.patch<T>(url, data, this.forceOpts);
      return req.data;
    } catch (e) {
      throw handleError(e as Error);
    }
  }

  async _patch<T = JsonObject, D = JsonObject>(
    url: string,
    data?: D
  ): Promise<AxiosResponse<T, D>> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] _PATCH: ${url}`);
    return await this.api.patch<T>(url, data, this.forceOpts);
  }

  async delete<T = JsonObject>(url: string): Promise<T> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] DELETE: ${url}`);
    try {
      const req = await this.api.delete<T>(url, this.forceOpts);
      return req.data;
    } catch (e) {
      throw handleError(e as Error);
    }
  }

  async _delete<T = JsonObject, D = JsonObject>(
    url: string
  ): Promise<AxiosResponse<T, D>> {
    await this.setupHeaders();
    DEBUG && console.info(`[api] _DELETE: ${url}`);
    return await this.api.delete<T>(url, this.forceOpts);
  }

  setHeaders(headers: RawAxiosRequestHeaders) {
    for (const h in headers) {
      this.api.defaults.headers.common[h] = headers[h];
    }
  }
}

function handleError(e: Error): ApiError {
  const error = new ApiError(e.message);

  Object.assign(error, {
    name: e.name,
    message: e.message,
  });

  if (e instanceof AxiosError) {
    Object.assign(error, {
      code: e.code || 'UnknownCode',
      cause: {},
      response: {
        status: e.response?.status || 500,
        statusText: e.response?.statusText || 'UnknownError',
      },
    });

    if (e.response?.data) {
      const data = e.response.data as ApiError;
      if (data.message && data.code) {
        error.message = data.message;
        error.code = data.code;
      }
      if (data.error) {
        error.error = data.error;
      }
    }
  }

  return error;
}
