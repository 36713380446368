'use client';

import { Client } from '@/lib/api';
import { DEBUG } from '@/lib/cfg';
import type { CustomAuthenticationResponseJSON, DeviceAuth } from '@/types/adminUser';
import { startAuthentication } from '@simplewebauthn/browser';
import { useRouter } from 'next/navigation';
import { useEffect, useState, use } from 'react';

export const dynamic = 'force-dynamic';

export default function Page(props: { searchParams: Promise<{ redirect: string }> }) {
  const searchParams = use(props.searchParams);
  const api = new Client();
  const router = useRouter();
  const [error, setError] = useState<Error>();

  const initDeviceAuth = async () => {
    try {
      const auth = await api.get<DeviceAuth>('admin/auth/web');

      const challengeId = auth.challengeId;
      const authResponse: CustomAuthenticationResponseJSON = await startAuthentication(
        auth.options
      );

      authResponse.challengeId = challengeId;
      try {
        const authResp = await fetch('/auth/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ authResponse }),
        });

        if (!authResp.ok) {
          const json = await authResp.json();
          if (json.error) {
            setError(new Error(json.error));
          } else {
            setError(new Error('Unknown Error'));
          }
          return;
        }

        router.replace(searchParams.redirect || '/');
        router.refresh();
      } catch (error) {
        setError(error as Error);
      }
    } catch (e) {
      // Likely user clicked cancel on browser popup...
      // Can just ignore it? Webauthn is optional...
      if (DEBUG) {
        console.error('[webauthn/auth]', e);
      }
    }
  };

  useEffect(() => {
    initDeviceAuth();
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return (
    <main className="mx-auto max-w-7xl text-center">
      <h2 className="mt-10" data-test="login-header">LOGIN</h2>
    </main>
  );
}
